.row-whale{
    color: #9467bd !important;
}

.row-shark{
    color: #f47f10 !important;
}

.row-sword{
    color: #39a02c !important;
}

.row-tuna{
    color: #d62728 !important;
}

.row-nemo{
    color: #2a77b4 !important;
}